import React, { useRef, useEffect, useState } from "react";
import {
  Card,
  Form,
  Row,
  Table,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
  InputGroup
} from "react-bootstrap";
import { BsPercent } from "react-icons/bs";
import generatePDF from "react-to-pdf";
import "./Template1.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { ToastContainer } from "react-toastify";
import { AiOutlineClose } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import AutoComplete from "../AutoComplete/AutoComplete";

export default function Templates8(props) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const ref = React.createRef();
  const { userInfo } = useSelector((state) => state.userInfo);
  const changeTemplate =  userInfo?.user_data?.role?.template?.includes('access');
  const { paymentDetails } = useSelector((state) => state.paymentDetails);

  const formatDate = userInfo?.account_preferences?.date_formate;
  const filterPaymentDetails = paymentDetails?.filter((row) => row?.isPrimary);
  const {
    requestType,
    isCreateInvoice,
    applyGST,
    applyDIS,
    toWords,
    addFields,
    removeFields,
    setApplyGST,
    setApplyDIS,
    disableSubmit,
    formik,
    setProjectName,
    showTemplate,
    handleProjectNameChange,
    handlePaidToChange,
    paymentDetailsEditable,
    setPaymentDetailsEditable,
    handleTemplatesChange,
  } = props;
  let subTotal = 0;
  let total = 0;
  let Quantity = 1;
  let discountVal = 0;
  let appliedgst = 0;
  let applieddiscount = 0;
  let netTotal = 0;
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const componentRef = useRef();
  function numberonly(evt) {
    var ch = String.fromCharCode(evt.which);

    if (!/[0-9 || .]/.test(ch)) {
      evt.preventDefault();
    }
  }

  function numberonly1(evt) {
    var ch = String.fromCharCode(evt.which);

    if (!/[0-9]/.test(ch)) {
      evt.preventDefault();
    }
  }
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (parseFloat(values.has_discounts) > 0) {
      setApplyDIS(true);
    }
    if (parseFloat(values.has_gst) > 0) {
      setApplyGST(true);
    }
    setIsDataLoaded(true);
  }, []);
  useEffect(() => {
    if (state?.download === "true" && isDataLoaded) {
      document.getElementById("pdf-button").click();
      navigate("/invoices");
    }
  }, [state?.download, isDataLoaded, navigate]);

  const {
    touched,
    errors,
    getFieldProps,
    handleSubmit,
    values,
    setFieldValue,
  } = formik;

  useEffect(() => {
    setProjectName(true);
  }, []);

  useEffect(() => {
    if (parseFloat(discountVal) !== parseFloat(values?.total_amount)) {
      setFieldValue("total_amount", discountVal);
    }
  }, [discountVal, applyGST, applyDIS, values]);

  function capitalizeWords(sentence) {
    // Split the sentence into words
    const words = sentence.split(" ");

    // Capitalize the first letter of each word
    const capitalizedWords = words?.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    // Join the capitalized words back into a sentence
    return capitalizedWords.join(" ");
  }

  return (
    <div className="main222">
      <Card style={{ width: 800 }}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <div ref={componentRef}>
            <div ref={ref}>
              <div className="line my-3"></div>
              <div className="mx-4">
                <Row>
                  <Col className="px-0">
                    <div className="" style={{marginBottom: "30px"}}>
                    <Form.Text className="text1 mb-0">Invoice</Form.Text>
                      {!isCreateInvoice && (
                        <>
                          <Form.Text
                            className="text11 text-start"
                            style={{ lineHeight: "1.2",marginTop:"-10px" }}
                          >
                            {values?.invoice_no}
                          </Form.Text>
                        </>
                      )}
                      </div>
                      <div className={`${requestType === "edit" || isCreateInvoice ? "" : ""} m-0`}
                      style={{paddingBottom:requestType === "edit" || isCreateInvoice?'':'0px'}}
                      >
                      <Form.Text className="text111 text-start" style={{ whiteSpace: "",}}>
                        PROJECT NAME:
                      </Form.Text>  {requestType === "edit" || isCreateInvoice ? (
                        <AutoComplete
                          autoComplete="off"
                          requestType=" project_name"
                          callbackFieldValue={handleProjectNameChange}
                          fieldValue={(values?.project_name || "").replace(/&amp;/g, "&")}
                          className="mb-2 w-100"
                          name="project_name"
                          id="project_name"
                          placeholder="Enter Project Name"
                          readOnly={!(requestType === "edit" || isCreateInvoice)}
                        />
                      ) : (
                        <Form.Text className="text4 text-start mt-0" style={{ width: "100%" }}>
                          {(values?.project_name || "").replace(/&amp;/g, "&")}
                        </Form.Text>
                      )}
                      {touched.project_name && errors.project_name && (
                        <Form.Control.Feedback className="d-block" type="invalid">
                          {errors.project_name}
                        </Form.Control.Feedback>
                      )}
                    </div>
                     
                     

                      <Form.Text className="text111 text-start d-block">
                        BILL TO:
                      </Form.Text>
                      {requestType === "edit" || isCreateInvoice ? (
                        <AutoComplete
                          autoComplete="off"
                          requestType="bills"
                          searchingFor="paid_to"
                          callbackFieldValue={handlePaidToChange}
                          fieldValue={(values?.paid_to || "").replace(/&amp;/g, "&")}
                          className=" mb-2 w-100"
                          name="paid_to"
                          id="paid_to"
                          placeholder="Enter Company Name"
                          readOnly={
                            !(requestType === "edit" || isCreateInvoice)
                          }
                        />
                      ) : (
                        <Form.Text
                          className="text4 text-start"
                          style={{ width: "100%" }}
                        >
                          {(values?.paid_to || "").replace(/&amp;/g, "&")}
                        </Form.Text>
                      )}
                      {touched.paid_to && errors.paid_to && (
                        <Form.Control.Feedback
                          className="d-block "
                          type="invalid"
                        >
                          {errors.paid_to}
                        </Form.Control.Feedback>
                      )}
                      {/* <Form.Text className="text111 text-start mt-2 d-block ">
                        ADDRESS:
                      </Form.Text> */}
                      {requestType === "edit" || isCreateInvoice ? (
                        <AutoComplete
                          requestType="bills"
                          searchingFor="address"
                          name="address"
                          callbackFieldValue={(e) => {
                            setFieldValue("address", e.target.value);
                          }}
                          fieldValue={(values?.address || "").replace(/&amp;/g, "&")}
                          as="textarea"
                          rows={1}
                          required
                          className=" w-100"
                          placeholder="Enter Address"
                          readOnly={
                            !(requestType === "edit" || isCreateInvoice)
                          }
                        />
                      ) : (
                        <Form.Text
                          className="text4 my-0 text-start "
                          style={{ width: "100%" }}
                        >
                          {(values?.address || "").replace(/&amp;/g, "&")}
                        </Form.Text>
                      )}
                      {touched.address && errors.address && (
                        <Form.Control.Feedback
                          className="d-block"
                          type="invalid"
                        >
                          {errors.address}
                        </Form.Control.Feedback>
                      )}
                    
                   
                  </Col>
                  <Col className="px-0  ps-3">
                    <div className="text11 mb-2 " style={{ marginTop: 30 }}>
                      {" "}
                      {userInfo?.account_preferences?.attached_logo ? (
                        <img
                          alt="temp"
                          src={`
                              ${process.env.REACT_APP_API_BASE_URL_IMAGE}${userInfo?.account_preferences?.attached_logo}
                            `}
                          style={{
                            height: "80px",
                          }}
                        />
                      ) : (
                        "{Logo}"
                      )}
                    </div>
                    <div className=" mt-100  " style={{ marginTop:requestType === "edit" || isCreateInvoice?49:9}}>
                      {/* <Form.Text
                        className="text111 my-1 mb-2 "
                        style={{ lineHeight: "1.2" }}
                      >
                        COMPANY NAME:
                      </Form.Text> */}
                      <div className={`${requestType === "edit" || isCreateInvoice ? "" : ""} m-0`}
                      style={{paddingBottom:requestType === "edit" || isCreateInvoice?'':'0px',marginTop:requestType === "edit" || isCreateInvoice? '0px':'-6px'}}
                      >
                     
                      <Form.Text className="text111 mt-0">
                        DATE:
                      </Form.Text>
                      {requestType === "edit" || isCreateInvoice ? (
                        <div className="">
                          <DatePicker
                            // wrapperClassName="datewrapper"
                            customInput={
                              <Form.Control
                                aria-describedby="basic-addon1"
                                className="my-0"
                              />
                            }
                            required
                            maxDate={new Date()}
                            name="date"
                            id="date"
                            dateFormat={
                              formatDate === null || formatDate === undefined
                                ? "dd MMM, yyyy"
                                : formatDate
                                    ?.replace(/D/g, "d")
                                    ?.replace(/Y/g, "y")
                            }
                            selected={
                              values?.date ? new Date(values?.date) : null 
                            }
                            onChange={(e) => {
                              setFieldValue(
                                "date",
                                e ? moment(e).format("YYYY-MM-DD") : "" 
                              );
                            }}
                            readOnly={
                              !(requestType === "edit" || isCreateInvoice)
                            }
                          />

                          <Form.Control.Feedback
                            className="d-block mb-1"
                            type="invalid"
                          >
                            {errors.date}
                          </Form.Control.Feedback>
                        </div>
                      ) : (
                        <Form.Text className="my-0 text-end" style={{display: "block",
                          color: "#454056",
                          fontSize: "15px",
                          fontWeight: "400",
                          border: "none",
                          }}>
                          {moment(values?.date).format(
                            `${
                              formatDate === null || formatDate === undefined
                                ? "dd MMM, yyyy"
                                : formatDate
                            }`
                          )}
                        </Form.Text>
                      )}
                      </div>
                      <Form.Text className="text111">
                        FROM:
                      </Form.Text>
                      <Form.Text
                        className="text11 mb-1"
                        style={{ lineHeight: "1.2" }}
                      >
                        {/* {userInfo?.account_preferences?.address
                          ? `${userInfo?.account_preferences?.address}, ${userInfo?.account_preferences?.city}, ${userInfo?.account_preferences?.state}`
                          : "{Conpany address, City, State}"} */}
                        IPH Technologies
                      </Form.Text>
                      {/* <Form.Text
                        className="text111 my-1 mb-2"
                        style={{ lineHeight: "1.2" }}
                      >
                        ADDRESS:
                      </Form.Text> */}
                      <Form.Text
                        className="text11 mt-0"
                        style={{ lineHeight: "1.2" }}
                      >
                        {userInfo?.account_preferences?.address
                          ? `${userInfo?.account_preferences?.address}, ${userInfo?.account_preferences?.city}, ${userInfo?.account_preferences?.state}`
                          : "{Conpany address, City, State}"}
                      </Form.Text>
                      <Form.Text
                        className="text11"
                        style={{ lineHeight: "1.2" }}
                      >
                        {userInfo?.account_preferences?.pincode
                          ? userInfo?.account_preferences?.pincode
                          : "{Pincode}"}
                      </Form.Text>
                      <Form.Text
                        className="text11"
                        style={{ lineHeight: "1.2" }}
                      >
                        {userInfo?.account_preferences?.contact_no
                          ? `${userInfo?.account_preferences?.contact_no}`
                          : "{Contact number}"}
                        {userInfo?.account_preferences?.alt_contact_no
                          ? `, ${userInfo?.account_preferences?.alt_contact_no}`
                          : ""}
                      </Form.Text>
                      <Form.Text
                        className="text11"
                        style={{ lineHeight: "1.2" }}
                      >
                        {userInfo?.account_preferences?.email_address
                          ? userInfo?.account_preferences?.email_address
                          : "{Email address}"}
                      </Form.Text>
                    </div>
                    {/* {!isCreateInvoice && (
                      <>
                        <Form.Text
                          className="text111 my-2"
                          style={{ lineHeight: "1.2" }}
                        >
                          INVOICE:
                        </Form.Text>
                        <Form.Text
                          className="text11 my-1"
                          style={{ lineHeight: "1.2" }}
                        >
                          {values?.invoice_no}
                        </Form.Text>
                      </>
                    )} */}
                  </Col>
                </Row>
              </div>

              <div className="mx-4">
                <Table striped hover className="mt-1">
                  <thead>
                    <tr>
                      <th
                        className="text9 text-start"
                        style={{ width: "135px" }}
                      >
                        Date
                      </th>
                      <th className="text9 text-start">Details</th>
                      <th
                        className="text9 text-start"
                        style={{ width: "135px" }}
                      >
                        Item Price
                      </th>
                      <th
                        className="text9 text-start"
                        style={{ width: "120px" }}
                      >
                      {values. item_unit}  
                      </th>
                      <th
                        className="text9 text-start"
                        style={{
                          width: "140px",
                        }}
                      >
                        Payments
                      </th>

                      {(requestType === "edit" ||
                        (isCreateInvoice &&
                          values?.voucher_descriptions?.length > 1)) && (
                        <th className="text9 text-right "></th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {values.voucher_descriptions?.map((row, index) => {
                      // const quantity = row.quantity
                      //   ? parseFloat(row.quantity)
                      //   : 1;
                      const quantity =
                        requestType !== "edit"
                          ? parseFloat(row.quantity)
                          : row.quantity
                          ? parseFloat(row.quantity)
                          : 1;
                      total += row.item_price
                        ? parseFloat(quantity * row.item_price)
                        : 0;

                      subTotal = total;
                      Quantity += quantity;

                      applieddiscount =
                      (subTotal * (values?.has_discounts || 0)) / 100;
                      netTotal = (
                            parseFloat(total) -
                            parseFloat(applyDIS ? applieddiscount : 0)
                          ).toFixed(2);

                      appliedgst = applyDIS
                              ? (netTotal * (values?.has_gst || 0)) / 100
                              : (subTotal * (values?.has_gst || 0)) / 100;

                      discountVal = applyDIS
                          ? (parseFloat(netTotal) + parseFloat(applyGST ? appliedgst : 0)).toFixed(2)
                          : (parseFloat(subTotal) + parseFloat(applyGST ? appliedgst : 0)).toFixed(2);
                      return (
                        <tr key={index} className="temp1row">
                          <td>
                            {requestType === "edit" || isCreateInvoice ? (
                              <DatePicker
                                wrapperClassName="datewrapper"
                                customInput={
                                  <Form.Control
                                    aria-describedby="basic-addon1"
                                    className="w-100"
                                  />
                                }
                                required
                                maxDate={new Date()}
                                name="date"
                                id="date"
                                // change
                                dateFormat={
                                  formatDate === null ||
                                  formatDate === undefined
                                    ? "dd MMM, yyyy"
                                    : formatDate
                                        ?.replace(/D/g, "d")
                                        ?.replace(/Y/g, "y")
                                }
                                selected={
                                  values.voucher_descriptions[index]?.item_date
                                    ? new Date(
                                        values.voucher_descriptions[
                                          index
                                        ].item_date
                                      )
                                    : null
                                }
                                onChange={(date) => {
                                  setFieldValue(
                                    `voucher_descriptions.${index}.item_date`,
                                    date
                                      ? moment(date).format("YYYY-MM-DD")
                                      : ""
                                  );
                                }}
                                readOnly={
                                  !(requestType === "edit" || isCreateInvoice)
                                }
                              />
                            ) : (
                              <Form.Text
                                className="text11 text-start"
                                style={{ width: "100%" }}
                                {...getFieldProps(
                                  `voucher_descriptions.${index}.item_date`
                                )}
                              >
                                {moment(
                                  getFieldProps(
                                    `voucher_descriptions.${index}.item_date`
                                  ).value
                                ).format(
                                  `${
                                    formatDate === null ||
                                    formatDate === undefined
                                      ? "DD MMM, YYYY"
                                      : formatDate
                                  }`
                                )}
                              </Form.Text>
                            )}
                            {touched.voucher_descriptions &&
                              touched.voucher_descriptions[index]?.item_date &&
                              errors.voucher_descriptions &&
                              errors.voucher_descriptions[index]?.item_date && (
                                <Form.Control.Feedback
                                  className="d-block"
                                  type="invalid"
                                >
                                  {errors.voucher_descriptions &&
                                    errors.voucher_descriptions[index]
                                      ?.item_date}
                                </Form.Control.Feedback>
                              )}
                          </td>
                          <td>
                            {requestType === "edit" || isCreateInvoice ? (
                              <AutoComplete
                                requestType="billdescriptions"
                                searchingFor="item_description"
                                name={`voucher_descriptions.${index}.item_description`}
                                callbackFieldValue={(e) =>
                                  setFieldValue(
                                    `voucher_descriptions.${index}.item_description`,
                                    e.target.value
                                  )
                                }
                                fieldValue={row.item_description}
                                as="textarea"
                                rows={1}
                                required
                                placeholder="Details"
                                readOnly={
                                  !(requestType === "edit" || isCreateInvoice)
                                }
                              />
                            ) : (
                              <Form.Text
                                className="text11 text-start"
                                style={{ width: "100%" }}
                                {...getFieldProps(
                                  `voucher_descriptions.${index}.item_description`
                                )}
                              >
                                {
                                  getFieldProps(
                                    `voucher_descriptions.${index}.item_description`
                                  ).value
                                }
                              </Form.Text>
                            )}
                            {touched.voucher_descriptions &&
                              touched.voucher_descriptions[index]
                                ?.item_description &&
                              errors.voucher_descriptions &&
                              errors.voucher_descriptions[index]
                                ?.item_description && (
                                <Form.Control.Feedback
                                  className="d-block"
                                  type="invalid"
                                >
                                  {errors.voucher_descriptions &&
                                    errors.voucher_descriptions[index]
                                      ?.item_description}
                                </Form.Control.Feedback>
                              )}
                          </td>
                          <td>
                            {requestType === "edit" || isCreateInvoice ? (
                              <Form.Control
                                maxLength={7}
                                required
                                rows={1}
                                readOnly={
                                  !(requestType === "edit" || isCreateInvoice)
                                }
                                onKeyPress={numberonly}
                                name="item_price"
                                {...getFieldProps(
                                  `voucher_descriptions.${index}.item_price`
                                )}
                                type="text"
                                placeholder="Amount"
                              />
                            ) : (
                              <Form.Text
                                className="text11 text-start"
                                style={{ width: "100%" }}
                                {...getFieldProps(
                                  `voucher_descriptions.${index}.item_price`
                                )}
                              >
                                {parseFloat(
                                  getFieldProps(
                                    `voucher_descriptions.${index}.item_price`
                                  ).value
                                ).toFixed(2)}
                              </Form.Text>
                            )}
                            {touched.voucher_descriptions &&
                              touched.voucher_descriptions[index]?.item_price &&
                              errors.voucher_descriptions &&
                              errors.voucher_descriptions[index]
                                ?.item_price && (
                                <Form.Control.Feedback
                                  className="d-block"
                                  type="invalid"
                                >
                                  {errors.voucher_descriptions &&
                                    errors.voucher_descriptions[index]
                                      ?.item_price}
                                </Form.Control.Feedback>
                              )}
                          </td>
                          <td>
                            {requestType === "edit" || isCreateInvoice ? (
                              <Form.Control
                                maxLength={4}
                                required
                                rows={1}
                                readOnly={
                                  !(requestType === "edit" || isCreateInvoice)
                                }
                                onKeyPress={numberonly1}
                                name="quantity"
                                {...getFieldProps(
                                  `voucher_descriptions.${index}.quantity`
                                )}
                                type="text"
                              />
                            ) : (
                              <Form.Text
                                className="text11 text-start"
                                style={{ width: "100%" }}
                                {...getFieldProps(
                                  `voucher_descriptions.${index}.quantity`
                                )}
                              >
                                {
                                  getFieldProps(
                                    `voucher_descriptions.${index}.quantity`
                                  ).value
                                }
                              </Form.Text>
                            )}
                            {touched.voucher_descriptions &&
                              touched.voucher_descriptions[index]?.quantity &&
                              errors.voucher_descriptions &&
                              errors.voucher_descriptions[index]?.quantity && (
                                <Form.Control.Feedback
                                  className="d-block"
                                  type="invalid"
                                >
                                  {errors.voucher_descriptions &&
                                    errors.voucher_descriptions[index]
                                      ?.quantity}
                                </Form.Control.Feedback>
                              )}
                          </td>
                          <td>
                            {requestType === "edit" || isCreateInvoice ? (
                              <Form.Control
                                type="text"
                                name={`voucher_descriptions.${index}.total_amount`}
                                value={
                                  row.total_amount ||
                                  (row.item_price * row.quantity).toFixed(2)
                                }
                                onChange={(e) => {
                                  const newTotalAmount =
                                    parseFloat(e.target.value) || 0;
                                  setFieldValue(
                                    `voucher_descriptions.${index}.total_amount`,
                                    newTotalAmount
                                  );
                                  setFieldValue(
                                    `voucher_descriptions.${index}.quantity`,
                                    "1"
                                  );
                                  //setFieldValue(`voucher_descriptions.${index}.item_price`,newTotalAmount);

                                  // If you want to update item_price based on the new total_amount and quantity:
                                  const newItemPrice = e.target.value;
                                  setFieldValue(
                                    `voucher_descriptions.${index}.item_price`,
                                    newItemPrice
                                  );
                                }}
                                onKeyPress={numberonly}
                                placeholder="Enter Total Amount"
                              />
                            ) : (
                              <Form.Text
                                className="text11 text-start"
                                style={{ width: "100%" }}
                              >
                                {(
                                  row.total_amount ||
                                  row.item_price * row.quantity
                                ).toFixed(2)}
                              </Form.Text>
                            )}
                          </td>

                          {(requestType === "edit" ||
                            (isCreateInvoice &&
                              values?.voucher_descriptions?.length > 1)) && (
                            <td className="text-center fs-5 pt-3">
                              <AiOutlineClose
                                className="text-danger mt-1"
                                style={{ cursor: "pointer" }}
                                onClick={() => removeFields(index, row?.id)}
                              />
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot >
                  <tr>
                    <td
                      colSpan={4}
                      style={{ borderWidth: "", padding: "1px 4px 4px 150px" }}
                    > <Row className="d-flex align-items-center  mb-1">
                        <Col className="px-0">
                          <Form.Text className="text111 text-start">
                            Sub Total
                          </Form.Text>
                        </Col>
                      </Row>
                      {!(
                        requestType === "view" &&
                        !isCreateInvoice &&
                        !values?.has_discounts
                      ) && (
                        <Row  style={{display:'flex', alignItem:'baseline'}}>
                          <Col className="ps-0">
                          {requestType === "view" && <Form.Label className="sizewt" style={{color:"#454056",opacity:"0.5"}}>Apply Discount</Form.Label>}
                          { requestType !== "view" && (
                            <Form.Group controlId="applyDIS" md="auto">
                              <Form.Check
                                className="sizewt text-start"
                                disabled={
                                  !(
                                    requestType === "edit" ||
                                    isCreateInvoice
                                  )
                                }
                                checked={applyDIS}
                                onChange={() => setApplyDIS(!applyDIS)}
                                type="checkbox"
                                label="Apply Discount"
                              />
                            </Form.Group>
                          )}
                          
                          </Col>
                          {applyDIS && (
                            <Col className=" d-flex flex-row pe-0  px-0 align-items-center">
                              <div className="d-flex flex-row sizewt align-items-center pb-1" >
                              {requestType === "edit" || isCreateInvoice ? (
                                <Form.Control
                                  className="gstdis shadow-none sizewt flex-row align-items-center"
                                  maxLength={3}
                                  type="number"
                                  readOnly={
                                    !(
                                      requestType === "edit" ||
                                      isCreateInvoice
                                    )
                                  }
                                  value={values?.has_discounts}
                                  // onChange={(e) => {
                                  //   setFieldValue(
                                  //     "has_discounts",
                                  //     e.target.value
                                  //   );
                                  // }}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const nonNegativeValue =
                                      parseFloat(inputValue) < 0
                                        ? 0
                                        : inputValue;
                                    setFieldValue(
                                      "has_discounts",
                                      nonNegativeValue
                                    );
                                  }}
                                  name="disctval"
                                  style={{
                                    border: requestType === "edit" || isCreateInvoice ? "1px solid #666276" : "none",
                                    padding: "0.2rem",
                                    right: "5px",
                                    width: "60px",
                                    height: "1.45em",
                                    fontSize: "14px",
                                    textAlign:'end',                             
                                    borderRadius:'6px'
                                  }}
                                />
                              ):(
                                <Form.Text style={{fontSize:"15px",marginTop:"0px"}}>
                                {values?.has_discounts}
                                </Form.Text>                              
                                 )}
                              </div>
                              <div className="d-flex align-items-center flex-row sizewt pb-1" style={{marginLeft:'5px'}}>
                                {" "}
                                <BsPercent className="" />
                                &nbsp;
                              </div>
                            </Col>
                          )}
                        </Row>
                      )}
                      {applyDIS && (
                            <Row className="d-flex align-items-center mb-1" style={{marginTop:"-3px"}}>
                              <Col className="px-0">
                                <Form.Text className="text111 text-start">Net Total</Form.Text>
                              </Col>
                            </Row>
                          )}
                     
                      {!(
                        requestType === "view" &&
                        !isCreateInvoice &&
                        !values?.has_gst
                      ) && (
                        <Row  style={{display:'flex', alignItem:'baseline'}}>
                          <Col className="ps-0">
                          {requestType === "view" && <Form.Label className="sizewt" style={{color:"#454056",opacity:"0.5"}}>Apply GST</Form.Label>}
                          { requestType !== "view" && (
                            <Form.Group controlId="ApplyGST" md="auto">
                              <Form.Check
                                className="sizewt text-start"
                                checked={applyGST}
                                onChange={() => setApplyGST(!applyGST)}
                                type="checkbox"
                                label="Apply GST"
                                disabled={
                                  !(
                                    requestType === "edit" ||
                                    isCreateInvoice
                                  )
                                }
                              />
                            </Form.Group>
                          )}
                          </Col>
                          {applyGST && (
                            <Col className=" d-flex flex-row pe-0  px-0 align-items-center">
                              <div className="d-flex flex-row sizewt align-items-center">
                              {requestType === "edit" || isCreateInvoice ? (
                                <Form.Control
                                  className="gstdis shadow-none sizewt flex-row align-items-center"
                                  name="has_gst"
                                  maxLength={5}
                                  type="number"
                                  value={values?.has_gst}
                                  readOnly={
                                    !(
                                      requestType === "edit" ||
                                      isCreateInvoice
                                    )
                                  }
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const nonNegativeValue =
                                      parseFloat(inputValue) < 0
                                        ? 0
                                        : inputValue;
                                    setFieldValue(
                                      "has_gst",
                                      nonNegativeValue
                                    );
                                  }}
                                  style={{
                                    border: requestType === "edit" || isCreateInvoice ? "1px solid #666276" : "none",
                                    padding: "0.2rem",
                                    right: "5px",
                                    width: "60px",
                                    height: "1.45em",
                                    fontSize: "14px",
                                    textAlign:'end',
                                    borderRadius:'6px'
                                  }}
                                />
                              ):
                              (
                                <Form.Text style={{fontSize:"15px",marginTop:"0px"}}>
                                  {values?.has_gst}
                                </Form.Text>
                              )}
                              </div>
                              <div className="d-flex align-items-center  flex-row sizewt pb-1" style={{marginLeft:'5px'}}>
                                {" "}
                                <BsPercent className="" />
                                &nbsp;
                              </div>
                            </Col>
                          )}
                        </Row>
                      )}
                    </td>
                     <td
                      colSpan={values.voucher_descriptions?.length > 1 ? 2 : 1}
                      style={{ 
                        verticalAlign: "top", 
                        paddingTop: "6px",
                      }}
                    >
                      <Row className="mb-1" style={{ width: "100%",marginTop:"0.1rem" }}>
                        <Col className="text-start p-0">
                          <Form.Text className="sizewt">
                            {`₹${total.toFixed(2)}`}
                          </Form.Text>
                        </Col>
                      </Row>
                      {applyDIS && (
                        <Row style={{ width: "100%", marginTop: applyGST ? '6px' : '7px' }}>
                          <Col className="text-start p-0">
                            <Form.Text className="sizewt">
                              {`₹${applieddiscount.toFixed(2)}`}
                            </Form.Text>
                          </Col>
                        </Row>
                      )}
                      <Row className="mb-1">
                            <Col className="text-start p-0" style={{marginTop:applyDIS?'8px':'0'}}>
                            {applyDIS && (
                              <Form.Text className="sizewt">
                              {`₹${netTotal}`}
                              </Form.Text>
                            )}
                            </Col>
                          </Row>
                      {applyGST && (
                        <Row style={{ width: "100%", marginBottom: applyDIS?"0px":"1px", marginTop: applyDIS ? '6px':'0px' }}>
                          <Col className="text-start p-0">
                            <Form.Text className="sizewt">
                              {`₹${appliedgst.toFixed(2)}`}
                            </Form.Text>
                          </Col>
                        </Row>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={4}
                      style={{ paddingLeft: "145px", backgroundColor: "#5e58d1" }}                   
                    >
                      <Row className="d-flex align-items-center">
                        <Col className="px-0">
                          <Form.Text
                            className="total1"
                          >
                            Total
                          </Form.Text>
                        </Col>
                      </Row>
                    </td>
                    <td
                      colSpan={
                        values.voucher_descriptions?.length > 1 ? 2 : 1
                      }
                      style={{ backgroundColor: "#5e58d1" }}
                    >
                      <Row className="">
                        <Col className="text-start p-0">
                          <Form.Text
                            className="total1 "
                            style={{ textAlign: "start" }}
                          >
                            {`₹${parseFloat(discountVal).toFixed(2)}`}
                          </Form.Text>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                </tfoot>
                </Table>
                <Row className="mt-1">
                  <Col sm={6} className="px-0">
                    <div style={{ maxWidth: "300px" }}>
                      <div className="d-flex  justify-content-between">
                        <Form.Text className="text111 text-start p-0 my-1">
                          Payment Details :
                        </Form.Text>
                        {requestType === "edit" ||
                          (isCreateInvoice && (
                            <Button
                              size="sm"
                              onClick={() => setPaymentDetailsEditable(true)}
                              variant="light"
                              className="text-primary"
                            >
                              Edit
                            </Button>
                          ))}
                      </div>
                      {!(requestType === "view" && !isCreateInvoice) &&
                        filterPaymentDetails
                          ?.sort((a, b) => b?.mode - a?.mode)
                          ?.map((payment) => (
                            <Form.Check
                              inline
                              label={capitalizeWords(payment?.mode)}
                              checked={values.payment_Details
                                ?.map((row) => row?.mode)
                                ?.includes(payment?.mode)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setFieldValue("payment_Details", [
                                    ...values.payment_Details,
                                    payment,
                                  ]);
                                } else {
                                  setFieldValue(
                                    "payment_Details",
                                    values.payment_Details?.filter(
                                      (row) => row?.mode !== payment?.mode
                                    )
                                  );
                                }
                              }}
                              type="checkbox"
                              disabled={
                                !(requestType === "edit" || isCreateInvoice)
                              }
                            />
                          ))}
                        {touched.payment_Details && errors.payment_Details && (
                        <Form.Control.Feedback
                          className="d-block"
                          type="invalid"
                        >
                          {errors?.payment_Details}
                        </Form.Control.Feedback>
                      )}
                      {values?.payment_Details?.length > 0
                        ? values?.payment_Details?.map((payment, index) => (
                            <Row className="d-flex" key={index}
                            style={{
                               borderBottom: index < values.payment_Details.length - 1 ? "1px solid #efecf3" : "none",
                               paddingBottom: index < values.payment_Details.length - 1 ? "7px" : "none"
                               }}
                            >
                              {payment?.mode === "bank" && (
                                <>
                                  {/**Add Bank name */}
                                  <Col sm={6} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      <b>Bank Name:</b>{" "}
                                    </Form.Text>
                                  </Col>
                                  <Col sm={6} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      {payment?.bank_name}
                                    </Form.Text>
                                  </Col>
                                  <Col sm={6} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      <b>Account Name:</b>{" "}
                                    </Form.Text>
                                  </Col>
                                  <Col sm={6} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      {payment?.accountName}
                                    </Form.Text>
                                  </Col>
                                  <Col sm={6} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      <b>Account Number:</b>{" "}
                                    </Form.Text>
                                  </Col>{" "}
                                  <Col sm={6} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      {payment?.accountNumber}
                                    </Form.Text>
                                  </Col>
                                  <Col sm={6} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      <b>IFSC code:</b>
                                    </Form.Text>
                                  </Col>
                                  <Col sm={6} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      {payment?.ifscCode}
                                    </Form.Text>
                                  </Col>
                                  {payment?.swiftCode !== "" && (
                                    <>
                                      <Col sm={6} xs={12} className="p-0">
                                        <Form.Text
                                          className="text4 text-start"
                                          style={{ width: "100%" }}
                                        >
                                          <b>Swift Code:</b>
                                        </Form.Text>
                                      </Col>{" "}
                                      <Col sm={6} xs={12} className="p-0">
                                        <Form.Text
                                          className="text4 text-start"
                                          style={{ width: "100%" }}
                                        >
                                          {payment?.swiftCode}
                                        </Form.Text>
                                      </Col>
                                    </>
                                  )}
                                </>
                              )}
                              {(payment?.mode === "UPI" ||
                                payment?.mode === "Paypal") && (
                                <>
                                  <Col sm={5} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      <b>{payment?.mode} Id:</b>
                                    </Form.Text>
                                  </Col>
                                  <Col sm={7} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      {payment?.paymentID}
                                    </Form.Text>
                                  </Col>
                                </>
                              )}
                              {payment?.mode === "other" && (
                                <>
                                  <Col sm={5} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      <b>Title:</b>
                                    </Form.Text>
                                  </Col>
                                  <Col sm={7} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      {payment?.title}
                                    </Form.Text>
                                  </Col>{" "}
                                  <Col sm={5} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      <b>Description:</b>
                                    </Form.Text>
                                  </Col>
                                  <Col sm={7} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      {payment?.discription}
                                    </Form.Text>
                                  </Col>
                                </>
                              )}
                            </Row>
                          ))
                        : null}{" "}
                    </div>
                  </Col>
                  <Col sm={6} className="px-0">
                  {values.other_info && (
                    <InputGroup className="">
                      <Form.Text className="text111 text-start p-0 my-1">
                        Other Details:
                      </Form.Text>
                      {requestType === "edit" || isCreateInvoice ? (

                      <AutoComplete
                        wrapperClassName={
                          isCreateInvoice ? "w-100" : "w-100"
                        }
                        requestType="bills"
                        searchingFor="otherInfo"
                        callbackFieldValue={(e) =>
                          setFieldValue("other_info", e.target.value)
                        }
                        fieldValue={values.other_info}
                        as="textarea"
                        rows={3}
                        className="w-100 rounded"
                        name="other_info"
                        id="other_info"
                        aria-describedby="basic-addon1"
                        placeholder="Enter Other Details"
                        readOnly={!(requestType === "edit" || isCreateInvoice)}
                      />
                      ):(
                        <Form.Text
                      className="text4  text-start"
                      style={{ width: "100%" }}
                    >
                      {values?.other_info}
                    </Form.Text>
                      )}
                    </InputGroup>
                  )}
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          {!showTemplate && (
            <Row className="position-sticky bottom-0 p-2 mt-5 mb-3">
              {(requestType === "edit" || isCreateInvoice) ? (
                <>
               
                  <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">Click to add row</Tooltip>
                      }
                    >
                      <Button
                        variant="secondary"
                        style={{ width: "100%" }}
                        onClick={addFields}
                      >
                        Add Row 
                      </Button>
                    </OverlayTrigger>
                  </Col>
                  <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click to submit the data to all invoices
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="primary"
                        type="submit"
                        style={{ width: "100%" }}
                        disabled={disableSubmit}
                      >
                        {disableSubmit ? (
                          <div className="d-flex justify-content-center">
                            <div
                              className="spinner-border"
                              role="status"
                              style={{ height: "21px", width: "21px" }}
                            ></div>
                          </div>
                        ) : (
                          "Save"
                        )}
                      </Button>
                    </OverlayTrigger>
                    <ToastContainer style={{ top: "60px" }} />
                  </Col>
                </>
              ) : (
                <>
                <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click to print template pdf
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="secondary"
                        className="primary w-100"
                        style={{
                          width: "100%",
                          // backgroundColor: "#5e58d1",
                          marginLeft: "3px",
                        }}
                        onClick={handlePrint}
                      >
                        Print 
                      </Button>
                    </OverlayTrigger>
                  </Col>
                  <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click to download pdf 
                        </Tooltip>
                      }
                    >
                      <Button
                        id="pdf-button"
                        onClick={() => {
                          generatePDF(componentRef, {
                            filename: `${values?.invoice_no}.pdf`,
                          });
                        }}
                        variant="secondary"
                        style={{
                          width: "100%",
                          marginRight: "3px",
                        }}
                      >
                        Download 
                      </Button>
                    </OverlayTrigger>
                  </Col>
                  <Col className="centerBtn">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          {changeTemplate ? "Click to change template" : "No access to change template"}
                        </Tooltip>
                      }
                    >
                      <Button
                        // variant="secondary"
                        style={{ width: "100%", backgroundColor:"#FD5631", pointerEvents: "auto" }}
                        onClick={() => {
                          if (!(requestType === "edit" || isCreateInvoice)) {
                            handleTemplatesChange(true);
                          } else {
                            navigate("/invoiceTemplate", {
                              state: {
                                values: formik?.values,
                                requestType: requestType || isCreateInvoice,
                              },
                            });
                          }
                          // handleTemplatesChange(true);

                        }}
                        disabled = {!changeTemplate}
                      >
                        Change Template
                      </Button>
                    </OverlayTrigger>
                  </Col>
                </>
              )}
            </Row>
          )}
        </Form>
      </Card>
    </div>
  );
}
