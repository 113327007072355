import logo from "./logo-1720439668.png";
import React, { useRef, useEffect, useState } from "react";
import {
  Card,
  Form,
  Row,
  Table,
  Col,
  Button,
  InputGroup,
  Container,
  Navbar,
} from "react-bootstrap";
import { BsPercent } from "react-icons/bs";
import generatePDF from "react-to-pdf";
import "./Document.css";
import { useReactToPrint } from "react-to-print";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const Document = ({ userInfo }) => {
  const { id } = useParams();
  const [invoiceData, setInvoiceData] = useState(null);
  const componentRef = useRef();
  const navigate = useNavigate();

  // Print the invoice
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Invoice",
  });
  // get invoice data
  const getPaymentDataData = async (id) => {
    const fetchData = async () => {
      try {
        const response = await fetch('/customData.json'); // Path is relative to the `public` folder
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const jsonData = await response.json();
        setInvoiceData(jsonData);
      } catch (error) {
        console.error("Error fetching JSON data:", error);
        return null;
      }
    };

    fetchData();
    // try {
    //   // const encodedInvoiceId = btoa(id);

    //   // Make a GET request to the endpoint with the encoded ID in the URL
    //   // const res = await axios.get(
    //   //   `${process.env.REACT_APP_API_BASE_URL}get-invoice-by-id/${encodedInvoiceId}`
    //   // );

    //   const res = await axios.post(
    //     `${process.env.REACT_APP_API_BASE_URL}get-invoice-by-id`,
    //     { invoice_id: id, public: "test" }
    //   );
    //   console.log("res" , res?.data);
    //   setInvoiceData(res?.data);

    //   return res.data;
    // } catch (error) {
    //   console.error("Error fetching payment data:", error);
    //   return null;
    // }
  };

  useEffect(() => {
    if (id) {
      getPaymentDataData(id);
    }
  }, [id]);

  const handleLogoClick = () => {
    // Clear user info from local storage
    localStorage.clear("user-info4343657897");
    // Navigate to the login page
    navigate("/login");
    // Optionally reload the page if necessary
  };

  return (
    <>
      {/* navbar */}
      <Navbar
        bg="light"
        variant="light"
        expand="lg"
        style={{
          backgroundColor: "light",
          zIndex: "1000",
        }}
      >
        <Container>
          <Navbar.Brand onClick={handleLogoClick} style={{ cursor: "pointer" }}>
            <img
              src={logo}
              //  src={`
              //     ${process.env.REACT_APP_API_BASE_URL_IMAGE}${userInfo?.account_preferences?.attached_logo}
              //       `}
              alt=""
              style={{ width: "100%", height: "55px", marginRight: "10px" }}
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-collapse" />
          <Navbar.Collapse id="navbar-collapse">
            <div
              style={{
                cursor: "pointer",
                borderRadius: "5px",
                padding: "8px 17px",
                marginLeft: "20px",
                backgroundColor: "#e0e0e0",
                display: "flex",
                alignItems: "center",
              }}
            >
              CONTACT
              <strong style={{ marginLeft: "5px" }}>
                IPH TECHNOLOGIES PVT LTD
              </strong>
            </div>
            <div
              className=""
              style={{ marginLeft: "auto", textAlign: "right" }}
            >
              <Navbar.Text>
                Client Name: <span>Deep</span>
              </Navbar.Text>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* another div-bar */}
      <div
        style={{
          boxShadow: "4px 0px 4px rgba(0, 0, 0, 0.2)",
          backgroundColor: "white",
          position: "fixed",
          zIndex: "999",
          width: "100%",
          marginTop: "56px",
        }}
      >
        <Container
          style={{
            alignItems: "center",
            padding: "20px 10px 20px 5px",
          }}
        >
          <Row>
            <Col className="col-md-6 d-md-flex p-0 align-items-end">
              <Row>
                <Col
                  className="pe-0 mb-2 mb-sm-0"
                  style={{ paddingLeft: "10px" }}
                >
                  <Button
                    variant=""
                    style={{
                      textTransform: "uppercase",
                      backgroundColor: "#FD5631",
                      color: "#FFFFFF",
                      width: "160px",
                    }}
                    onClick={() => {
                      generatePDF(componentRef, {
                        filename: `invoice_no.pdf`,
                      });
                    }}
                  >
                    Export PDF
                  </Button>
                </Col>
                <Col className="ms-sm-2 px-0">
                  <Button
                    variant=""
                    style={{
                      textTransform: "uppercase",
                      backgroundColor: "#5e58d1",
                      color: "#fff",
                      width: "160px",
                    }}
                    onClick={handlePrint}
                  >
                    Print
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col
              className="col-md-6 p-0"
              style={{
                width: "",
                textAlign: "right",
                border: "1px solid midnight",
              }}
            >
              <p style={{ margin: "0", textAlign: "right" }}>
                <strong style={{ marginRight: "10px" }}>Invoice ID:</strong>{" "}
                {invoiceData?.data?.invoice_no}
              </p>
              <p style={{ margin: "0", textAlign: "right" }}>
                <strong style={{ marginRight: "10px" }}>Due Date:</strong>
                {invoiceData?.data?.date}
              </p>
              <p style={{ margin: "0", textAlign: "right" }}>
                <strong style={{ marginRight: "10px" }}>Amount Due:</strong>
                {invoiceData?.data?.total_amount}
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        style={{ marginTop: "180px", marginBottom: "50px" }}
        className="px-3 px-sm-0"
      >
        <div className="main222">
          <Card
            style={{
              width: "800px",
              boxShadow:
                "0 1px 3px 0 rgba(0,0,0,0.1), 0 1px 2px -1px rgba(0,0,0,0.1)",
            }}
          >
            <div ref={componentRef}>
              <div>
                <Row className="d-flex justify-content-between  p-4">
                  <Col className="d-flex justify-content-start ps-0" sm={6}>
                    {" "}
                    <img
                      alt="temp"
                      src={logo}
                      style={{
                        height: "80px",
                      }}
                    />
                    <div className=" p-0 m-0 ps-1">
                      {" "}
                      <Form.Text
                        className="d-block m-0"
                        style={{ fontSize: "19px", color: "#f79234" }}
                      >
                        {" "}
                        IPH{" "}
                      </Form.Text>
                      <Form.Text
                        className="d-block m-0"
                        style={{
                          fontSize: "15px",
                          fontWeight: "600",
                          color: "#006cb5",
                        }}
                      >
                        {" "}
                        TECHNOLOGIES{" "}
                      </Form.Text>
                      <Form.Text
                        className="d-block m-0"
                        style={{ fontSize: "19px", color: "#f79234" }}
                      >
                        {" "}
                        PVT. LTD.
                      </Form.Text>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <Form.Text
                        className="d-block mt-0 text-end "
                        style={{ fontSize: "35px" }}
                      >
                        INVOICE
                      </Form.Text>

                      <Form.Text
                        className="text11 p-0 "
                        style={{ lineHeight: "1.2", marginTop: "-6px" }}
                      >
                        {invoiceData?.data?.invoice_no}
                      </Form.Text>
                    </div>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between px-4">
                  <Col sm={6} className="ps-0">
                    <div>
                      <Form.Text
                        className="text111 text-start"
                        style={{ whiteSpace: "" }}
                      >
                        PROJECT NAME:
                      </Form.Text>
                      <Form.Text
                        className="text4 text-start mt-0"
                        style={{ width: "100%" }}
                      >
                        {invoiceData?.data?.project_name}
                      </Form.Text>
                    </div>
                    <Form.Text className="text111 text-start mt-0">
                      BILL TO:
                    </Form.Text>
                    <Form.Text
                      className="text4   text-start mt-0"
                      style={{ width: "50%" }}
                    >
                      {invoiceData?.data?.paid_to}
                    </Form.Text>
                    <Form.Text
                      className="text4  text-start mt-0"
                      style={{ width: "100%" }}
                    >
                      {invoiceData?.data?.address}
                    </Form.Text>
                  </Col>
                  <Col sm={6} className="px-0 mt-0">
                    <div className="">
                      <Form.Text className="text111 me-3">DATE:</Form.Text>
                      <Form.Text className="text11 mt-0 me-3">
                        {invoiceData?.data?.date}
                      </Form.Text>
                    </div>
                    <Form.Text className="text111 mt-0 pe-3">FROM:</Form.Text>
                    <Row className="text-end fixedbottom">
                      {" "}
                      <Form.Text
                        className="text11 mt-0"
                        style={{ lineHeight: "1.2" }}
                      >
                        IPH Technologies
                      </Form.Text>
                      <Form.Text
                        className="text11"
                        style={{ lineHeight: "1.2" }}
                      ></Form.Text>
                      <Form.Text
                        className="text11"
                        style={{ lineHeight: "1.2" }}
                      >
                        JB Emperor Square, 25,26 2nd Floor, JB, 1212S,
                      </Form.Text>
                      <Form.Text
                        className="text11"
                        style={{ lineHeight: "1.2" }}
                      ></Form.Text>
                      <Form.Text
                        className="text11"
                        style={{ lineHeight: "1.2" }}
                      >
                        Kanpur - Lucknow Rd, near Apollo Medics
                      </Form.Text>

                      <Form.Text
                        className="text11"
                        style={{ lineHeight: "1.2" }}
                      ></Form.Text>
                      <Form.Text
                        className="text11"
                        style={{ lineHeight: "1.2" }}
                      >
                        Hospital, LDA Colony, Lucknow, Uttar Pradesh
                      </Form.Text>
                      <Form.Text
                        className="text11"
                        style={{ lineHeight: "1.2" }}
                      ></Form.Text>
                      <Form.Text
                        className="text11"
                        style={{ lineHeight: "1.2" }}
                      >
                        226014
                      </Form.Text>

                      <Form.Text
                        className="text11"
                        style={{ lineHeight: "1.2" }}
                      ></Form.Text>
                      <Form.Text
                        className="text11"
                        style={{ lineHeight: "1.2" }}
                      >
                        1234567980, 5566556688
                      </Form.Text>
                      <Form.Text
                        className="text11"
                        style={{ lineHeight: "1.2" }}
                      ></Form.Text>
                      <Form.Text
                        className="text11"
                        style={{ lineHeight: "1.2" }}
                      >
                        traddoucroddeibu-4677@yopmail.com
                      </Form.Text>
                    </Row>
                  </Col>
                </Row>
                <div className="mx-4 ">
                  <Table striped hover className="mt-4">
                    <thead>
                      <tr>
                        <th
                          className="text9"
                          style={{
                            backgroundColor: "#f7b731",
                            width: "140px",
                          }}
                        >
                          Date
                        </th>
                        <th
                          className="text9"
                          style={{ backgroundColor: "#f7b731" }}
                        >
                          Details
                        </th>
                        <th
                          className="text9"
                          style={{
                            backgroundColor: "#f7b731",
                            width: "120px",
                          }}
                        >
                          Item Price
                        </th>
                        <th
                          className="text9"
                          style={{
                            backgroundColor: "#f7b731",
                            width: "110px",
                          }}
                        >
                          Quantity
                        </th>
                        <th
                          className="text9"
                          style={{
                            backgroundColor: "#f7b731",
                            width: "140px",
                          }}
                        >
                          Payments
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceData &&
                        invoiceData?.data?.bill_description?.map((item,index) => (
                          <tr key={index} style={{ height: "10px" }} className="temp1row">
                            <td>
                              <Form.Text
                                className="text11 text-start"
                                style={{ width: "100%" }}
                              >
                                {item?.date}
                              </Form.Text>
                            </td>
                            <td>
                              <Form.Text
                                className="text11  text-start"
                                style={{ width: "100%" }}
                              >
                                {item.item_description}
                              </Form.Text>
                            </td>
                            <td>
                              <Form.Text
                                className="text11  text-start"
                                style={{ width: "100%" }}
                              >
                                {item.item_price}
                              </Form.Text>
                            </td>
                            <td>
                              <Form.Text
                                className="text11  text-start"
                                style={{ width: "100%" }}
                              >
                                {item.quantity}
                              </Form.Text>
                            </td>
                            <td>
                              <Form.Text
                                className="text11  text-start"
                                style={{ width: "100%" }}
                              >
                                {item.item_price * item.quantity}
                              </Form.Text>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td
                          colSpan={4}
                          style={{
                            borderWidth: "",
                            padding: "1px 4px 4px 150px",
                          }}
                        >
                          <Row className="d-flex align-items-center  mb-1">
                            <Col className="px-0">
                              <Form.Text className="text111 text-start">
                                Sub Total
                              </Form.Text>
                            </Col>
                          </Row>
                          {invoiceData?.data?.has_discounts && (<Row
                            style={{ display: "flex", alignItem: "baseline" }}
                          >
                            <Col className="ps-0">
                              <Form.Label
                                className="sizewt"
                                style={{ color: "#454056", opacity: "0.5" }}
                              >
                                Apply Discount
                              </Form.Label>
                            </Col>
                            <Col className=" d-flex flex-row pe-0  px-0 align-items-center">
                              <div className="d-flex flex-row sizewt align-items-center pb-1">
                                <Form.Text
                                  style={{
                                    fontSize: "15px",
                                    marginTop: "0px",
                                  }}
                                >
                                  {invoiceData?.data?.discount_details?.discount_percentage}
                                </Form.Text>
                              </div>
                              <div
                                className="d-flex align-items-center flex-row sizewt pb-1"
                                style={{ marginLeft: "5px" }}
                              >
                                {" "}
                                <BsPercent className="" />
                                &nbsp;
                              </div>
                            </Col>
                          </Row>)}
                          <Row
                            className="d-flex align-items-center mb-1"
                            style={{ marginTop: "-3px" }}
                          >
                            <Col className="px-0">
                              <Form.Text className="text111 text-start">
                                Net Total
                              </Form.Text>
                            </Col>
                          </Row>
                          {invoiceData?.data?.has_gst && (<Row
                            style={{ display: "flex", alignItem: "baseline" }}
                          >
                            <Col className="ps-0">
                              <Form.Label
                                className="sizewt"
                                style={{ color: "#454056", opacity: "0.5" }}
                              >
                                Apply GST
                              </Form.Label>
                            </Col>
                            <Col className=" d-flex flex-row pe-0  px-0 align-items-center">
                              <div className="d-flex flex-row sizewt align-items-center">
                                <Form.Text
                                  style={{
                                    fontSize: "15px",
                                    marginTop: "0px",
                                  }}
                                >
                                  {invoiceData?.data?.gst_details?.gst_percentage}
                                </Form.Text>
                              </div>
                              <div
                                className="d-flex align-items-center  flex-row sizewt pb-1"
                                style={{ marginLeft: "5px" }}
                              >
                                {" "}
                                <BsPercent className="" />
                                &nbsp;
                              </div>
                            </Col>
                          </Row>)}
                        </td>
                        <td
                          colSpan={2 || 1}
                          style={{
                            verticalAlign: "top",
                            paddingTop: "6px",
                          }}
                        >
                          <Row className="mb-1" style={{ width: "100%" }}>
                            <Col className="text-start p-0">
                              <Form.Text className="sizewt">
                              {` ₹${invoiceData?.data?.sub_total_amount}`}
                              </Form.Text>
                            </Col>
                          </Row>

                          {invoiceData?.data?.has_discounts && (<Row
                            style={{
                              width: "100%",
                              marginTop: "4px" || "7px",
                            }}
                          >
                            <Col className="text-start p-0">
                              <Form.Text className="sizewt">
                              {` ₹${invoiceData?.data?.discount_details?.discount_amount}`}
                              </Form.Text>
                            </Col>
                          </Row>)}
                          <Row className="mb-1">
                            <Col
                              className="text-start p-0"
                              style={{ marginTop: "6px" || "0" }}
                            >
                              <Form.Text className="sizewt">
                              {` ₹${invoiceData?.data?.net_total_amount}`}
                              </Form.Text>
                            </Col>
                          </Row>

                          {invoiceData?.data?.has_gst && (<Row
                            style={{
                              width: "100%",
                              marginTop: "7px" || "1px",
                            }}
                          >
                            <Col className="text-start p-0">
                              <Form.Text className="sizewt">
                              {` ₹${invoiceData?.data?.gst_details?.gst_amount}`}
                              </Form.Text>
                            </Col>
                          </Row>)}
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={4}
                          style={{
                            paddingLeft: "145px",
                            backgroundColor: "#f7b731",
                          }}
                        >
                          <Row className="d-flex align-items-center">
                            <Col className="px-0">
                              <Form.Text className="total1">Total</Form.Text>
                            </Col>
                          </Row>
                        </td>
                        <td
                          colSpan={2 || 1}
                          style={{ backgroundColor: "#f7b731" }}
                        >
                          <Row className="">
                            <Col className="text-start p-0">
                              <Form.Text
                                className="total1 "
                                style={{ textAlign: "start" }}
                              >
                                {` ₹${invoiceData?.data?.total_amount}`}
                              </Form.Text>
                            </Col>
                          </Row>
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                  <Row className="mt-1 pb-4">
                    <Col sm={6} className="ps-0">
                      <div style={{ maxWidth: "300px" }}>
                        <div className="d-flex  justify-content-between">
                          <Form.Text className="text111 text-start p-0 my-1">
                            Payment Details :
                          </Form.Text>
                        </div>
                        {invoiceData?.data?.payment_details?.map((payment, index) => (
                            <Row className="d-flex" key={index}
                              style={{
                               borderBottom: index < invoiceData.data.payment_details.length - 1 ? "1px solid #efecf3" : "none",
                               paddingBottom: index < invoiceData.data.payment_details.length - 1 ? "7px" : "none"
                              }}
                            >
                              {/* <Col sm={12} xs={12} className="p-0">
                                <Form.Text
                                  className="text111 text-start p-0 my-1"
                                  style={{
                                    width: "100%",
                                    borderBottom: "1px solid #efecf3",
                                  }}
                                >
                                  {(payment?.mode === "bank" ||
                                    payment?.mode === "other") &&
                                    capitalizeWords(payment?.mode)}
                                </Form.Text>
                              </Col> */}
                              {payment?.mode === "bank" && (
                                <>
                                  {/** Add Bank Name */}
                                  <Col sm={6} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      <b>Bank Name:</b>{" "}
                                    </Form.Text>
                                  </Col>
                                  <Col sm={6} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      {payment?.bank_name}
                                    </Form.Text>
                                  </Col>
                                  <Col sm={6} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      <b>Account Name:</b>{" "}
                                    </Form.Text>
                                  </Col>
                                  <Col sm={6} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      {payment?.account_name}
                                    </Form.Text>
                                  </Col>
                                  <Col sm={6} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      <b>Account Number:</b>{" "}
                                    </Form.Text>
                                  </Col>{" "}
                                  <Col sm={6} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      {payment?.account_number}
                                    </Form.Text>
                                  </Col>
                                  <Col sm={6} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      <b>IFSC code:</b>
                                    </Form.Text>
                                  </Col>
                                  <Col sm={6} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      {payment?.ifsc_code}
                                    </Form.Text>
                                  </Col>
                                  {payment?.swiftCode !== "" && (
                                    <>
                                      <Col sm={6} xs={12} className="p-0">
                                        <Form.Text
                                          className="text4 text-start"
                                          style={{ width: "100%" }}
                                        >
                                          <b>Swift Code:</b>
                                        </Form.Text>
                                      </Col>{" "}
                                      <Col sm={6} xs={12} className="p-0">
                                        <Form.Text
                                          className="text4 text-start"
                                          style={{ width: "100%" }}
                                        >
                                          {payment?.swift_code}
                                        </Form.Text>
                                      </Col>
                                    </>
                                  )}
                                </>
                              )}
                              {(payment?.mode === "UPI" ||
                                payment?.mode === "Paypal") && (
                                <>
                                  <Col sm={5} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      <b>{payment?.mode} Id:</b>
                                    </Form.Text>
                                  </Col>
                                  <Col sm={7} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      {payment?.payment_id}
                                    </Form.Text>
                                  </Col>
                                </>
                              )}
                              {payment?.mode === "other" && (
                                <>
                                  <Col sm={5} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      <b>Title:</b>
                                    </Form.Text>
                                  </Col>
                                  <Col sm={7} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      {payment?.title}
                                    </Form.Text>
                                  </Col>{" "}
                                  <Col sm={5} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      <b>Description:</b>
                                    </Form.Text>
                                  </Col>
                                  <Col sm={7} xs={12} className="p-0">
                                    <Form.Text
                                      className="text4 text-start"
                                      style={{ width: "100%" }}
                                    >
                                      {payment?.description}
                                    </Form.Text>
                                  </Col>
                                </>
                              )}
                            </Row>
                          ))}
                      </div>
                    </Col>
                    <Col sm={6} className="px-0">
                      <InputGroup className="">
                        <Form.Text className="text111 text-start p-0 my-1">
                          Other Details:
                        </Form.Text>
                        <Form.Text
                          className="text4  text-start"
                          style={{ width: "100%" }}
                        >
                        {invoiceData?.data?.other_details?.other_info}
                          {/* values . other_info */}
                        </Form.Text>
                      </InputGroup>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>{" "}
          </Card>
        </div>
      </div>
    </>
  );
};
export default Document;
