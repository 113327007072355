import { FETCH_INVOICE_SUCCESS } from "../store/Invoice/InvoiceActionTypes";
import { Modal, Spinner } from "react-bootstrap";
import moment from "moment";
export const exportBtnType = (
  columnsToExport = [1, 2],
  dispatchCallType,
  handleImportModal
) => [
  {
    extend: "pageLength",
    className: `btn btn-primary btn-sm`,
  },

  // Export options for Excel, CSV, and PDF
  !window.location.pathname.includes("user-roles") && {
    text: "Export",
    className: "btn btn-primary btn-sm",
    extend: "collection",
    buttons: [
      {
        extend: "excel",
        text: "Export to Excel",
        exportOptions: {
          columns: columnsToExport,
          orthogonal: "export",
        },
      },
      {
        extend: "csv",
        text: "Export to CSV",
        exportOptions: {
          columns: columnsToExport,
          orthogonal: "export",
        },
      },
      {
        extend: "pdf",
        text: "Export to PDF",
        exportOptions: {
          columns: columnsToExport,
          orthogonal: "export",
        },
        customize: function (doc) {
          // Get the number of columns in the table
          const numColumns = doc.content[1].table.body[0].length;

          // Calculate the width for each column
          const columnWidth = 100 / numColumns;

          // Set the widths of all columns to fit the full page width
          doc.content[1].table.widths = Array(numColumns).fill(
            columnWidth + "%"
          );

          // Set alignment for each column
          for (let i = 0; i < numColumns; i++) {
            // Center align the title (first row)
            doc.content[1].table.body[0][i].alignment = "center";

            // Left align the data (remaining rows)
            for (let j = 1; j < doc.content[1].table.body.length; j++) {
              doc.content[1].table.body[j][i].alignment = "center";
            }
          }
        },
      },

      // {
      //   text: "Export All Data",
      //   action: function () {
      //     handleCustomExport(); // Call the function to open the modal
      //   },
      // },
    ],
  },
  // Import button
  dispatchCallType === "FETCH_CASHBOOK_ENTRIES_SUCCESS" &&
    window.location.pathname.includes("cashbook") && {
      className: `btn btn-primary btn-sm`,
      text: "Import",
      action: function () {
        handleImportModal();
      },
    },
];

const transformDate = (date) => moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");

// Utility function to transform data
const transformData = (data, dispatchCallType) => {
  const responseData = [...data];
  if (dispatchCallType === "FETCH_INVOICE_SUCCESS") {
    return responseData?.map((row) => ({
      ...row,
      date: transformDate(row.date),
      invoice_no: row?.voucher_no,
      //total_amount: (row?.total_amount).toString(),
      //voucher_no: undefined,
    }));
  }
  if (dispatchCallType === "FETCH_MEDIA_SUCCESS") {
    return responseData?.map((row) => {
      // Check if 'extension' has a value, otherwise return 'N/A'
      const fileName = row.title || row.url;
      // Extract the extension from the file name
      const extensionMatch = fileName.match(/\.([a-zA-Z0-9]+)$/);
      const fileExtensionValue = extensionMatch ? extensionMatch[1] : "N/A";

      // Use row.extension if it exists; otherwise use fileExtensionValue
      const extensionValue = row.extension || fileExtensionValue;

      return {
        ...row,
        extension: extensionValue,
      };
    });
  }

  // if (dispatchCallType === "FETCH_VOUCHER_SUCCESS")
  //   return responseData?.map((row) => ({
  //     ...row,
  //     date: transformDate(row.date),
  //     voucher_no: row?.invoice_no,
  //     // invoice_no: undefined,
  //   }));
  return responseData?.map((row) => ({
    ...row,
    date: transformDate(row.date),
  }));
};

export const dataTableConfig = (
  url,
  requestType = "GET",
  columns,
  userInfo,
  defaultSortedIndex,
  defaultSortedIndexShortType = "desc",
  dispatch,
  dispatchCallType,
  additionalFilter,
  handleImportModal,
  handleCustomExport,
  enableOrdering,
  addEntry = false
) => {
  const sorting =
    dispatchCallType !== "FETCH_CASHBOOK_ENTRIES_SUCCESS"
      ? [[defaultSortedIndex, defaultSortedIndexShortType]]
      : [];
  const columnSorting =
    dispatchCallType !== "FETCH_CASHBOOK_ENTRIES_SUCCESS" ? true : false;

  const checkAll = document.querySelector(".checkAll");
  const checkedCheckBoxes = document.querySelectorAll(".checkBox:checked");
  const multipleDeleteBtn = document.getElementById("multipleDeleteBtn");
  if (checkedCheckBoxes?.length===0 && checkAll?.checked) {
    checkAll.checked = false;
    multipleDeleteBtn.style.display = "none";
  }

  /// It is for that how many field you want to export in pdf or Excel or CSV
  const columnsToExport = columns
    .map((row, index) => {
      if (row.data === null || row.data === "id" || row.data === "url") {
        return null;
      }
      // if (row.data === "extension")
      if (
        dispatchCallType !== "FETCH_INVOICE_SUCCESS" &&
        row.data === "voucher_no"
      ) {
        // Conditionally exclude "voucher_no" or "invoice_no"
        return null;
      }
      if (
        dispatchCallType === "FETCH_INVOICE_SUCCESS" &&
        row.data === "invoice_no"
      ) {
        return null;
      }

      return index;
    })
    ?.filter((key) => key !== null);

  const overlay = document.getElementById("DataTables_Table_1_processing");
  if (overlay) {
    overlay.classList.remove("card");
  }
  const processingHTML = `<div id="processingModal" class="modal fade show d-block" style="background: rgba(0, 0, 0, 0.5);">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body text-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <p class="mt-2 text-white">Loading...</p>
      </div>
    </div>
  </div>
</div>
`;

  return {
    language: {
      paginate: {
        next: "&#8594;", // or '→'
        previous: "&#8592;", // or '←'
      },
      searchPlaceholder: "Search",
      search: "",
      processing: processingHTML, // Custom processing indication
    },
    dom:
      dispatchCallType === "FETCH_CASHBOOK_ENTRIES_SUCCESS" && addEntry
        ? "<'row g-0'<'col-sm-8'Bl><'col-sm-4'f>>" +
          "<'row g-0 my-4'<'col-sm-12'<'custom-input-field-wrapper'>r>>" +
          "<'row g-0 mt-3'<'col-sm-12'tr>>" +
          "<'row g-0 mt-3'<'col-sm-5'i><'col-sm-7'p>>"
        : "<'row g-0'<'col-sm-8'Bl><'col-sm-4'f>>" +
          "<'row g-0 mt-3'<'col-sm-12'tr>>" +
          "<'row g-0 mt-3'<'col-sm-5'i><'col-sm-7'p>>",
    buttons: exportBtnType(
      columnsToExport,
      dispatchCallType,
      handleImportModal,
      handleCustomExport
    )?.filter((row) => typeof row === "object"),
    destroy: true,
    processing: addEntry ? false : true,
    ordering: enableOrdering,
    serverSide: true,
    pageLength: 10,
    lengthMenu: [
      [10, 25, 50, 100, 500, -1],
      ["10 rows", "25 rows", "50 rows", "100 rows", "500 rows", "Show all"],
    ],
    searching: dispatchCallType !== "FETCH_CASHBOOK_ENTRIES_SUCCESS", // Conditionally show search field
    //searching: true,
    lengthChange: false,
    stateSave: true,
    
    ajax: {
      url,
      type: requestType,
      headers: {
    'Authorization': `Bearer ${userInfo?.userInfo?.token}`, // Include the token in the header
    'Accept': 'application/json'
  },
      data: function (d) {
        const searchTerm = document.querySelector('.dataTables_filter input')?.value || '';
        return { ...d, ...additionalFilter, search: searchTerm };
      },
      dataSrc: function (res) {
        const searchTerm = document.querySelector('.dataTables_filter input')?.value || '';
        const filteredData = res.data.filter(row =>
          Object.values(row).some(value => 
            String(value).toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
        dispatch({
          type: dispatchCallType || FETCH_INVOICE_SUCCESS,
          data: transformData(filteredData, dispatchCallType),
          otherInfo: res,
        });
        return transformData(filteredData, dispatchCallType);
        
        // Commented By Pulkit
        // const responseData = { ...res };
        // delete responseData["draw"];
        // delete responseData["data"];
        // delete responseData["input"];
        // // delete responseData["recordsFiltered"];
        // // delete responseData["recordsTotal"];
        // dispatch({
        //   type: dispatchCallType || FETCH_INVOICE_SUCCESS,
        //   data: transformData(res?.data, dispatchCallType),
        //   otherInfo: responseData,
        // });
        // return transformData(res?.data, dispatchCallType);
      },
    },
    columns,

    aaSorting: sorting,
    columnDefs: [
      {
        orderable: columnSorting,
        targets: "_all",
      },
    ],
  };
};
